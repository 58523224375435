import Repository from '@/repositories/RepositoryFactory';
import rs from 'jsrsasign';
import VueRouter from 'vue-router';
import { setAuthToken, setBaseURL } from '../../plugins/axios';
import { setUser } from '@sentry/vue';
import router from '@/router/index';

const AuthenticationRepository = Repository.get('client', 'authentication');
const AdminAuthenticationRepository = Repository.get('admin', 'authentication');
const LocationServerRepository = Repository.get('common', 'location');

const { isNavigationFailure, NavigationFailureType } = VueRouter;

let logoutInProgress = false;

export default {
	namespaced: true,
	state: {
		sessionMode: null,
		apiServer: null,
		videoController: null,
		userInfos: null,
		userObject: null,
		externalToken: null,
		externalTokenParsed: null,
		sessionToken: null,

		// TODO: Is there a better place for this?
		roleId: null,
	},
	mutations: {
		SET_SESSION_MODE (state, mode) {
			state.sessionMode = mode;
		},
		QUERY_LOCATION_SERVER (state, payload) {
			state.apiServer = payload.apiServer;
			setBaseURL(`https://${payload.apiServer}`);
			localStorage.apiServer = payload.apiServer;

			state.videoController = payload.serverAddress;
			localStorage.videoController = payload.serverAddress;
		},
		DO_LOGIN (state, payload) {
			state.userObject = payload;
			state.sessionToken = payload.UserInfos.Token;
			localStorage.sessionToken = payload.UserInfos.Token;
			setAuthToken(payload.UserInfos.Token);
			setUser({
				id: payload.UserInfos.UserId,
				email: payload.UserInfos.Email,
			});

			localStorage.sessionMode = state.sessionMode;
			// TODO: Remove from here (make a getter)
			if (state.sessionMode === 'admin') {
				state.roleId = 1;
			} else {
				state.roleId = payload.UserInfos.RoleId;
			}
		},
		LOGOUT (state) {
			localStorage.clear();
			state.sessionMode = null;
			state.sessionToken = null;
			state.apiServer = null;
			state.videoController = null;
			setBaseURL(null);
			setAuthToken(null);
			setUser(null);
		},
		setExternalToken (state, { rawToken, parsedToken }) {
			state.externalToken = rawToken;
			state.externalTokenParsed = parsedToken;
		},
		initialize (state) {
			// TODO: In the future, we can load all stored state from
			//       localStorage with https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store
			if (localStorage.sessionMode === 'admin' && localStorage.sessionToken && localStorage.apiServer) {
				state.sessionMode = 'admin';
				state.sessionToken = localStorage.sessionToken;
				state.apiServer = localStorage.apiServer;
			} else if (localStorage.sessionToken && localStorage.apiServer && localStorage.videoController) {
				state.sessionMode = 'normal';
				state.sessionToken = localStorage.sessionToken;
				state.apiServer = localStorage.apiServer;
				state.videoController = localStorage.serverAddress;
			} else {
				// If localStorage does not have what we expect it to have,
				// make sure it is completely clean.
				localStorage.clear();
				return;
			}

			setAuthToken(state.sessionToken);
			setBaseURL(`https://${state.apiServer}`);
		},
	},
	actions: {
		async queryLocationServer ({ commit }, email) {
			const response = await LocationServerRepository.queryLocationServer(email);
			commit('QUERY_LOCATION_SERVER', response);
			return response;
		},
		async doLoginSSO ({ commit }, data) {
			commit('SET_SESSION_MODE', 'common');
			commit('DO_LOGIN', await AuthenticationRepository.loginSSO(data.code, data.sessionState, data.org));
		},
		async doLogin ({ commit }, data) {
			commit('SET_SESSION_MODE', 'common');
			commit('DO_LOGIN', await AuthenticationRepository.login(data.email, data.password));
		},
		async doAdminLogin ({ commit }, data) {
			commit('SET_SESSION_MODE', 'admin');
			commit('DO_LOGIN', await AdminAuthenticationRepository.login(data.email, data.password));
		},
		async doLoginWithToken ({ commit, state }) {
			if (state.sessionMode === 'admin') {
				commit('DO_LOGIN', await AdminAuthenticationRepository.getUserData());
			} else {
				commit('DO_LOGIN', await AuthenticationRepository.getUserData());
			}
		},
		async doLogout ({ commit, state }, message) {
			// Prevent loops in logout
			if (logoutInProgress) {
				return;
			}

			logoutInProgress = true;

			let redirectTo = '/login';

			// If we have a session token, send a logout message to the server,
			// otherwise just clear the localStorage.
			if (state.sessionToken) {
				if (state.sessionMode === 'admin') {
					redirectTo = '/admin/login';
					commit('LOGOUT', await AdminAuthenticationRepository.logout());
				} else {
					commit('LOGOUT', await AuthenticationRepository.logout());
				}
			} else {
				commit('LOGOUT');
			}

			if (message) {
				message.auth = true;
				commit('alerts/add', message);
			}

			router.push(redirectTo).catch(error => {
				// Prevent `NavigationDuplicated: Avoided redundant navigation to current location`
				// if we are already redirecting to login because multiple simultaneous HTTP calls
				// returned 401 by swallowing such errors and re-throwing all others.
				if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
					throw error;
				}
			});

			logoutInProgress = false;
		},
		async requestExternalToken ({ commit }) {
			const rawToken = await AuthenticationRepository.getExternalToken();

			const tokenData = new rs.KJUR.jws.JWS();
			try {
				tokenData.parseJWS(rawToken);
			} catch (ex) {
				// TODO: Proper error handling
				// captureException(ex);
				// commit(M.SET_ERROR, 'Server returned invalid token.');
				// console.log(ex);
				commit('setExternalToken', { rawToken: null, parsedToken: null });
				return;
			}
			const parsedToken = JSON.parse(tokenData.parsedJWS.payloadS);

			// NOTE: This function DOES NOT validate the JWT since it is not safe to save the
			// shared SECRET in a public file. However, since this token is used to connect to
			// a server (via WS), it is easily determined if it is invalid at that point.

			commit('setExternalToken', { rawToken, parsedToken });
		},
		async forgotPassword ({ commit }, email) {
			const response = await AuthenticationRepository.forgotPassword(email);
			return response;
		},
	},
	getters: {
		hasStoredSession (state) {
			return (!!state.sessionToken) && (!!state.apiServer);
		},
		hasStoredCommonSession (state) {
			return (!!state.sessionToken) && (!!state.apiServer) && state.sessionMode !== 'admin';
		},
		hasStoredAdminSession (state) {
			return (!!state.sessionToken) && (!!state.apiServer) && state.sessionMode === 'admin';
		},
		getRoleName (state) {
			return state.userObject.UserInfos.RoleName;
		},
		getVideoController (state) {
			return state.videoController;
		},
		getUserEmail (state) {
			return state.userObject.UserInfos.Email;
		},
		isVideoUser (state) {
			return state.roleId === 5 || state.roleId === 8;
		},
	},
};
