<template>
	<v-app id="main-app">
		<v-container class="fill-height" fluid>
			<div v-if="loading" class="text-center mx-auto px-10 pb-9">
				<v-progress-circular
					indeterminate
					:size="50"
					color="white"
				></v-progress-circular>
			</div>
			<div v-if="!loading" class="mx-0 mx-sm-auto mx-md-auto mx-lg-auto mx-xl-auto px-0 pb-9">
				<v-card v-if="showForm" class="mx-auto px-10 pb-9" :width="cardWidth">
					<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
					<v-card-subtitle class="text-center py-6 headline">{{ subtitle }}</v-card-subtitle>
					<v-divider></v-divider>
					<v-card-subtitle class="text-center py-6 headline">{{ topMessage }}</v-card-subtitle>
					<div class="d-flex justify-end" v-if="canLoginMobile || !usesSSO">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
								>
								<v-icon @click="revertIcon"> {{ iconEye }} </v-icon>
								</v-btn>
							</template>
							<span>{{$t('showHideContents')}}</span>
						</v-tooltip>
					</div>
					<v-card-text class="text-center">
						<v-form @submit.prevent="activateAccount">
							<div v-if="!usesSSO">
								<v-text-field
									v-model="password"
									label="New Password"
									placeholder=" "
									name="password"
									outlined
									required
									spellcheck="false"
									:autocomplete="showNewPassword ? 'off' : 'current-password'"
									:rules="[validPassword]"
									:type="showInputs ? 'text' : 'password'"
									@click:append="showNewPassword = !showNewPassword"/>

								<v-text-field
									label="Reenter Password"
									placeholder=" "
									v-model="confirmPassword"
									name="password"
									outlined
									required
									spellcheck="false"
									:autocomplete="showPassword ? 'off' : 'current-password'"
									:rules="[validPasswordConfirmation]"
									:type="showInputs ? 'text' : 'password'"
									@click:append="showPassword = !showPassword"
									@keyup.enter="clickButton"/>
							</div>
							<div v-if="canLoginMobile">
								<div class="mb-10">
									<v-text-field
										v-model="pin"
										label="New PIN"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[validPin]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<v-text-field
										v-model="confirmPin"
										label="Reenter PIN"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[validPinConfirmation]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<p class="text-justify">
										{{ $t('pinDescription') }}
									</p>
								</div>
								<div class="mb-10">
									<v-text-field
										v-model="panicCode"
										label="New Panic Code"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[validPanic]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<v-text-field
										v-model="confirmPanicCode"
										label="Reenter Panic Code"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[validPanicConfirmation]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<p class="text-justify">
										{{ $t('panicCodeDescription') }}
									</p>
								</div>
							</div>
						</v-form>
					</v-card-text>
					<v-card-actions class="d-flex justify-center mb-6">
						<v-btn :disabled="!completed" class="px-16 py-6" color="primary" @click="activateAccount()">{{ $t('activateAccount') }}</v-btn>
					</v-card-actions>
				</v-card>
				<div v-if="!showForm" class="d-flex flex-column">
					<p class="text-center white--text messagge-size mb-6">{{ finalMessage }}</p>
					<hr v-if="canLoginMobile || canLoginWeb" style="margin: 1em 0">
					<v-row v-if="canLoginMobile" class="text-center white--text messagge-size mb-6">
						<v-col sm="12">Get the {{ $t('productName') }} mobile app</v-col>
						<v-col md="6" sm="12">
							<a target="_blank" :href="$t('appleAppStoreUrl')">
								<img :src="appleAppStore" alt="Link to Apple App Store" width="165" height="50">
							</a>
						</v-col>
						<v-col md="6" sm="12">
							<a target="_blank" :href="$t('googlePlayUrl')">
								<img :src="googlePlayStore" alt="Link to Google Play Store" width="165" height="50">
							</a>
						</v-col>
					</v-row>
					<hr v-if="canLoginMobile && canLoginWeb" style="margin: 1em 0">
					<p v-if="canLoginMobile && canLoginWeb" class="text-center white--text messagge-size mb-6">Go to the admin/reports dashboard</p>
					<v-btn v-if="canLoginWeb" class="px-16 text-center" color="primary" @click="goToLogin()">{{ $t('goToLogin') }}</v-btn>
				</div>
			</div>
			<v-footer color="transparent" absolute padless>
				<v-col class="text-center white--text" cols="12">
					&copy;{{ new Date().getFullYear() }} SaferMobility, LLC &#183; {{ appVersion }}
				</v-col>
			</v-footer>
		</v-container>
	</v-app>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory';
import appleAppStore from '@/images/app-store-165x50.png';
import googlePlayStore from '@/images/google-play-165x50.png';
const { LOGIN_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

const UserRepository = Repository.get('client', 'user');

export default {
	data () {
		return {
			appVersion: process.env.VUE_APP_VERSION,
			logo: LOGIN_LOGO,
			loaded: false,
			showForm: true,
			showInputs: false,
			case: '',
			password: '',
			confirmPassword: '',
			showPassword: false,
			showNewPassword: false,
			pin: '',
			confirmPin: '',
			panicCode: '',
			confirmPanicCode: '',
			canLoginMobile: true,
			canLoginWeb: true,
			finalMessage: '',
			usesSSO: false,
			appleAppStore,
			googlePlayStore,
		};
	},
	watch: {
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
	},
	computed: {
		validPassword () {
			if (this.password.length < 8) return 'Password must have eight or more characters';
			return true;
		},
		validPasswordConfirmation () {
			if (this.password.length >= 8 && this.password !== this.confirmPassword) return 'Password confirmation does not match';
			return !!this.password;
		},
		validPin () {
			if (this.pin.length < 4) return 'PIN must have four digits';
			return true;
		},
		validPinConfirmation () {
			if (this.confirmPin.length > 3 && this.pin !== this.confirmPin) return 'PIN confirmation does not match';
			return !!this.pin;
		},
		validPanic () {
			if (this.panicCode.length < 4) return 'Panic Code must have four digits';
			if (this.pin === this.panicCode) return 'Panic Code must be different from PIN';
			return true;
		},
		validPanicConfirmation () {
			if (this.confirmPanicCode === this.pin) return 'Panic Code must be different from PIN';
			if (this.panicCode !== this.confirmPanicCode) return 'Panic Code confirmation does not match';
			return !!this.confirmPanicCode;
		},
		passwordFieldsMatch () {
			return this.validPassword === true && this.validPasswordConfirmation === true;
		},
		pinFieldsMatch () {
			return this.validPin === true && this.validPinConfirmation === true;
		},
		panicFieldsMatch () {
			return this.validPanic === true && this.validPanicConfirmation === true;
		},
		completed () {
			if (this.usesSSO) {
				if (this.canLoginMobile) {
					return this.pinFieldsMatch && this.panicFieldsMatch;
				}
				return true;
			} else {
				if (this.canLoginMobile) {
					return this.passwordFieldsMatch && this.pinFieldsMatch && this.panicFieldsMatch;
				} else {
					return this.passwordFieldsMatch;
				}
			}
		},
		subtitle () {
			return 'Activate Your Account';
		},
		topMessage () {
			if (this.usesSSO) {
				if (this.canLoginMobile) {
					return 'To complete your account activation, set your PIN and Panic Code below.';
				} else {
					return 'To complete your account activation, press the button below.';
				}
			} else {
				if (this.canLoginMobile) {
					return 'To complete your account activation, set your password, PIN, and Panic Code below.';
				} else {
					return 'To complete your account activation, set your password below.';
				}
			}
		},
		iconEye () {
			return this.showInputs === true ? 'mdi-eye' : 'mdi-eye-off';
		},
		loading () {
			return this.loaded;
		},
		cardWidth () {
			var width = window.innerWidth;
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				if (width <= 320 && width > 0) {
					return '300px';
				} else if (width <= 375 && width > 320) {
					return '350px';
				} else return '400px';
			case 'sm': return '500px';
			case 'md': return '500px';
			case 'lg': return '500px';
			case 'xl': return '600px';
			default: return '500px';
			}
		},
	},
	methods: {
		async activateAccount () {
			const body = {
				token: this.$route.params.token,
				sso: this.usesSSO,
				show_pin: this.canLoginMobile,
				password: this.password,
				password_confirm: this.confirmPassword,
				pin: this.pin,
				pin_confirm: this.confirmPin,
				duress: this.panicCode,
				duress_confirm: this.confirmPanicCode,
			};
			const response = await UserRepository.confirmValidAndSetPassword(body);
			if (response.success) {
				this.finalMessage = response.description;
				this.showForm = false;
			} else {
				this.finalMessage = response.description;
				this.showForm = false;
			}
		},
		clickButton () {
			return this.activateAccount();
		},
		revertIcon () {
			this.showInputs = !this.showInputs;
		},
		goToLogin () {
			this.$router.push({ name: 'Login' });
		},
	},
	async mounted () {
		try {
			this.loaded = true;
			await this.$store.dispatch('authentication/queryLocationServer', this.$route.params.email);
			await this.$store.dispatch('getCsrfToken');
			const response = await UserRepository.getShowPin(this.$route.params.token);
			this.canLoginMobile = response.body?.can_login_mobile;
			this.canLoginWeb = [2, 5, 6, 8, 9, 12].includes(response.body?.role_id);
			this.usesSSO = response.body?.uses_sso;
		} catch (e) {
			this.showForm = false;
			this.finalMessage = e.message;
		} finally {
			this.loaded = false;
		}
	},
};
</script>
<style scoped>
#main-app {
	background: linear-gradient(#557DBE, #3C5886);
	height: 100%;
	overflow: auto;
}
.messagge-size {
	font-size: 1.5rem;
}
</style>
