import Repository from '@/repositories/RepositoryFactory';

const UserRepository = Repository.get('client', 'user');

export default {
	namespaced: true,
	state: {
		users: [],
		user: null,
	},
	getters: {

	},
	mutations: {
		LOAD_USERS: (state, payload) => {
			state.users = payload;
		},
		LOAD_EMPLOYEE_BY_ID: (state, payload) => {
			state.user = payload;
		},
		LOAD_EMPLOYEE_BY_EMAIL: (state, payload) => {
			state.user = payload;
		},
	},
	actions: {
		async getUsersFromRepo ({ commit }, object) {
			commit('LOAD_USERS', await UserRepository.get(object));
		},

		async storeUser ({ commit }, object) {
			const response = await UserRepository.store(object);
			return response;
		},

		async updateUser ({ commit }, object) {
			const response = await UserRepository.update(object);
			return response;
		},

		async getEmployeeById ({ commit }, id) {
			commit('LOAD_EMPLOYEE_BY_ID', await UserRepository.getEmployeeById(id));
		},

		async getEmployeeByEmail ({ commit }, object) {
			commit('LOAD_EMPLOYEE_BY_EMAIL', await UserRepository.getEmployeeByEmail(object));
		},
	},
};
