import BranchOffice from '@/lib/models/Client/BranchOffice';
import BranchType from '@/lib/models/Client/BranchType';
import User from '@/lib/models/Client/User';
import { BranchOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function addOrUpdateBranch (body) {
	const response = await $http.post('/api/client/addbranchoffice', body);

	if (response.data?.success) {
		const res = {
			body: response.data.body,
			newBranch: new BranchOffice(response.data.body.organizationobject),
		};
		return res;
	} else {
		throw new BranchOfficeError(response.data.description);
	}
}

async function modifyBranch (body, errorMessage) {
	const response = await $http.post('/api/client/managebranchoffices', body);

	if (response.data?.success) {
		return response.data.body;
	} else {
		throw new BranchOfficeError(errorMessage);
	}
}

export default {
	async get (object = {}) {
		const body = {
			case: 'GET-ALL-BRANCH-OFFICES',
			...object,
		};

		const response = await $http.post('/api/client/getofficesv2', body, {
			responseType: object.format === 'csv' ? 'blob' : undefined,
		});

		if (object.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchOffices = data.map(br => {
				const branch = new BranchOffice(br);
				return branch;
			});
			return branchOffices;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async getBranchesInRegion (regionId) {
		const body = {
			case: 'GET-BRANCH-OFFICES-BY-REGION',
			regionalOfficeId: `${regionId}`,
		};

		const response = await $http.post('/api/client/getbranchbyregionalid', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchOffices = data.map(br => {
				const branch = new BranchOffice(br);
				return branch;
			});
			return branchOffices;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async getBranchById (id) {
		const body = {
			case: 'GET-BRANCH-OFFICE-BY-BRANCHOFFICEID',
			branchOfficeId: `${id}`,
		};

		const response = await $http.post('/api/client/getbranchbyidv2', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject;
			const branch = new BranchOffice(data[0]);
			return branch;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Offices');
		}
	},
	async getBranchTypes () {
		const body = {
			case: 'GET-BRANCH-OFFICE-TYPES',
		};

		const response = await $http.post('/api/client/getknownoperators', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const branchTypes = data.map(brt => {
				const branchType = new BranchType(brt);
				return branchType;
			});
			return branchTypes;
		} else {
			throw new BranchOfficeError('Error retrieving Branch Types');
		}
	},
	async store (object) {
		object.case = 'ADD-BRANCH-OFFICE';

		return addOrUpdateBranch(object);
	},
	async update (object) {
		object.case = 'UPDATE-BRANCH-OFFICE';

		return addOrUpdateBranch(object);
	},
	async updateBranchDetails (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-DETAILS';

		return modifyBranch(object, 'Error updating Branch Details');
	},
	async updateBranchSchedule (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-WEEKSCHEDULE';

		return modifyBranch(object, 'Error updating Branch Office Schedule');
	},
	async updateBranchHolidays (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-HOLIDAYS';

		return modifyBranch(object, 'Error updating Branch Holidays');
	},
	async getBranchEmployees (id) {
		const body = {
			param_branchid: `${id}`,
		};

		const response = await $http.post('/api/client/getbranchemployees', body);

		if (response.data?.success) {
			const data = response.data.body.userobject || [];
			const branchEmployees = data.map(be => {
				const user = new User(be);
				return user;
			});
			return branchEmployees;
		} else {
			return [];
		}
	},
	async updateTimers (object) {
		object.case = 'UPDATE-BRANCH-OFFICE-TIMERS';

		return modifyBranch(object, 'Error updating Branch Timers');
	},
	async getBranchOfficesForMap () {
		const body = {
			case: 'GET-FOR-MAP-COMPACT',
		};

		const response = await $http.post('/api/client/getofficesformap', body);

		if (response.data?.success) {
			const { organizationobject, organizationfeatures } = response.data.body;
			return { branchesForMap: organizationobject || [], organizationFeatures: organizationfeatures };
		}

		throw new Error('Error retrieving Branches for Map');
	},
	async setBranchTestMode (object) {
		object.case = 'SET-BRANCH-MODE';

		const response = await $http.post('/api/client/setbranchmode', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new BranchOfficeError('Error updating Branch mode');
		}
	},
};
