import Repository from '@/repositories/RepositoryFactory';
// import notificationsound from '@/store/plugins/alarm-notification-sound';

const NotificationRepository = Repository.get('client', 'notification');

export default {
	namespaced: true,
	state: {
		notification: null,
		allNotifications: [],
		allNotificationsCounter: 0,
		alarmsCounter: 0,
		anomalyCounter: 0,
		openings: [],
		closings: [],
		servicings: [],
		alarmNotifications: [],
		anomalyNotifications: [],
		ensNotifications: [],
		allNotificationsId: 0,
		alarmsNotificationsId: 0,
		anomalyNotificationsId: 0,
		selectedType: 'notifications',
		isLoadMore: false,
		branchNotificationSettingsForUser: null,
		notificationAllowed: null,

		alert: false,
		alertMessage: '',
		alertType: 'success',
		count: 0,
	},
	getters: {

	},
	mutations: {
		CHANGE_ALERT_STATUS: (state, payload) => {
			state.alert = payload.alert;
			state.alertMessage = payload.alertMessage;
			state.alertType = payload.alertType;
		},
		UPDATE_IS_LOAD_MORE: (state, payload) => {
			state.isLoadMore = payload;
		},
		UPDATE_ALL_NOTIFICATIONS_ID: (state, payload) => {
			state.allNotificationsId = payload;
		},
		UPDATE_ALARMS_NOTIFICATIONS_ID: (state, payload) => {
			state.alarmsNotificationsId = payload;
		},
		UPDATE_ANOMALY_NOTIFICATIONS_ID: (state, payload) => {
			state.anomalyNotificationsId = payload;
		},
		RESET_NOTIFICATIONS_IDS: (state) => {
			state.allNotificationsId = 0;
			state.alarmsNotificationsId = 0;
			state.anomalyNotificationsId = 0;
		},
		LOAD_ALL_NOTIFICATIONS: (state, payload) => {
			var c = [...payload];
			var result = c.reduce((unique, o) => {
				if (!unique.some(obj => obj.NotificationId === o.NotificationId)) {
					unique.push(o);
				}
				return unique;
			}, []);
			state.allNotifications = result;
			const counters = updateCounters(result);
			state.allNotificationsCounter = counters.all;
			state.alarmsCounter = counters.alarms;
			state.anomalyCounter = counters.anomaly;
		},
		LOAD_ALARM_NOTIFICATIONS: (state, payload) => {
			if (state.isLoadMore) {
				state.alarmNotifications.push(...payload);
			} else {
				state.alarmNotifications = payload;
			}
			let total = 0;
			payload.forEach(element => {
				if (element.Queue) {
					total++;
				}
			});
			state.alarmsCounter = total;
		},
		LOAD_ANOMALY_NOTIFICATIONS: (state, payload) => {
			if (state.isLoadMore) {
				state.anomalyNotifications.push(...payload);
			} else {
				state.anomalyNotifications = payload;
			}
			let total = 0;
			payload.forEach(element => {
				if (element.Queue) {
					total++;
				}
			});
			state.anomalyCounter = total;
		},
		LOAD_ENS_NOTIFICATIONS: (state, payload) => {
			state.ensNotifications = payload;
		},
		UPDATE_ALL_NOTIFICATIONS: (state, payload) => {
			if (payload.TaskType === 'CHECK_IN' || payload.TaskType === 'CHECK_OUT') {
				payload.BranchAction = payload.TaskDescription;
				state.alarmNotifications.push(payload);
				state.alarmsCounter++;
			} else {
				if (payload.Msg.indexOf('SYSTEM EVENT') > -1 && payload.Msg.indexOf('Opening') > -1) {
					state.anomalyCounter++;
					payload.BranchAction = 'Opening';
					state.anomalyNotifications.push(payload);
				} else if (payload.Msg.indexOf('SYSTEM EVENT') > -1 && payload.Msg.indexOf('Opened') > -1) {
					state.anomalyCounter++;
					payload.BranchAction = 'Opened';
					state.anomalyNotifications.push(payload);
				}
				if (payload.Msg.indexOf('SYSTEM EVENT') > -1 && payload.Msg.indexOf('Closing') > -1) {
					state.anomalyCounter++;
					payload.BranchAction = 'Closing';
					state.anomalyNotifications.push(payload);
				} else if (payload.Msg.indexOf('SYSTEM EVENT') > -1 && payload.Msg.indexOf('Closed') > -1) {
					state.anomalyCounter++;
					payload.BranchAction = 'Closed';
					state.anomalyNotifications.push(payload);
				}
			}
			state.allNotifications.push(payload);
			state.allNotificationsCounter++;
			// notificationsound(state.count);
		},
		UPDATE_ALARM_NOTIFICATIONS: (state, payload) => {
			state.alarmNotifications.push(payload);
			state.allNotifications.push(payload);
			state.allNotificationsCounter++;
			state.alarmsCounter++;
			// notificationsound(state.count);
		},
		UPDATE_OPENINGS: (state, payload) => {
			state.openings.push(payload);
		},
		UPDATE_CLOSINGS: (state, payload) => {
			state.closings.push(payload);
		},
		UPDATE_SERVICINGS: (state, payload) => {
			state.servicings.push(payload);
		},
		UPDATE_SELECTED_TYPE: (state, payload) => {
			state.selectedType = payload;
		},
		ADD_COUNT: (state) => {
			state.count = 1;
		},
		REMOVE_COUNT: (state) => {
			state.count = 0;
		},
		LOAD_NOTIFICATION: (state, payload) => {
			state.notification = payload;
		},
		LOAD_BRANCH_NOTIFICATION_SETTINGS: (state, payload) => {
			state.branchNotificationSettingsForUser = payload.notificationobject;
		},
		LOAD_ALLOW_NOTIFICATION_SETTING: (state, payload) => {
			state.notificationAllowed = payload.notificationobject?.AllowNotifications;
		},
	},
	actions: {
		async getAllNotifications ({ commit }, object) {
			commit('LOAD_ALL_NOTIFICATIONS', await NotificationRepository.getAllNotifications(object));
		},
		async getNotificationById ({ commit }, object) {
			commit('LOAD_NOTIFICATION', await NotificationRepository.getNotificationById(object));
		},
		async getAlarmNotifications ({ commit }, object) {
			commit('LOAD_ALARM_NOTIFICATIONS', await NotificationRepository.getAlarmNotifications(object));
		},
		async getPanicNotifications ({ commit }, object) {
			commit('LOAD_PANIC_NOTIFICATIONS', await NotificationRepository.getPanicNotifications(object));
		},
		async getAnomalyNotifications ({ commit }, object) {
			commit('LOAD_ANOMALY_NOTIFICATIONS', await NotificationRepository.getAnomalyNotifications(object));
		},
		async getEmergencyNotifications ({ commit }, object) {
			commit('LOAD_EMERGENCY_NOTIFICATIONS', await NotificationRepository.getEmergencyNotifications(object));
		},
		async getEnsNotifications ({ commit }, object) {
			commit('LOAD_ENS_NOTIFICATIONS', await NotificationRepository.getEnsNotifications(object));
		},
		async markAsReadNotification ({ commit }, id) {
			const response = await NotificationRepository.markAsReadNotification(id);
			return response;
		},
		async markAllAsRead ({ commit }, object) {
			const response = await NotificationRepository.markAllAsRead(object);
			return response;
		},
		async getEnsMessageTemplate ({ commit }, object) {
			const response = await NotificationRepository.getEnsMessageTemplate(object);
			return response;
		},
		async sendEnsMessage ({ commit }, object) {
			const response = await NotificationRepository.sendEnsMessage(object);
			return response;
		},
		async updateNotificationSettings ({ commit }, object) {
			const response = await NotificationRepository.updateNotificationSettings(object);
			return response;
		},
		async getNotificationSettingsForuser ({ commit }, object) {
			const response = await NotificationRepository.getNotificationSettingForUser(object);
			commit('LOAD_BRANCH_NOTIFICATION_SETTINGS', response);
			return response;
		},
		async getNotificationsAllowed ({ commit }, object) {
			const response = await NotificationRepository.getNotificationsAllowed(object);
			commit('LOAD_ALLOW_NOTIFICATION_SETTING', response);
			return response;
		},
		async updateNotificationsAllowed ({ commit }, object) {
			const response = await NotificationRepository.updateNotificationsAllowed(object);
			return response;
		},
	},
};

function updateCounters (payload) {
	const counters = {
		all: 0,
		panic: 0,
		alarms: 0,
		anomaly: 0,
		emergency: 0,
	};

	payload.forEach(element => {
		if (element.Queue) {
			counters.all++;
			if (isAlarm(element)) {
				counters.alarms++;
			}
			if (isPanic(element)) {
				counters.panic++;
			}
			if (isAnomaly(element)) {
				counters.anomaly++;
			}
			if (isEmergency(element)) {
				counters.emergency++;
			}
		}
	});
	return counters;
}

function isAlarm (notification) {
	// return notification.Packet.BranchAction?.includes('Trouble') &&
	// !notification.HalfMsg.includes('panic') &&
	// !notification.HalfMsg.includes('duress');
	// return notification.BranchAction === 'Check In Alarm';
	return notification.NotificationType === 2 || notification.NotificationType === 15;
}

function isPanic (notification) {
	return notification.HalfMsg.includes('panic') ||
	notification.HalfMsg.includes('duress');
	// notification.HalfMsg.includes('Alarm');
}

function isAnomaly (notification) {
	return notification.NotificationType === 0;
}

function isEmergency (notification) {
	return [10, 11, 12].includes(notification.NotificationType);
}
