import Repository from '@/repositories/RepositoryFactory';

const BranchRepository = Repository.get('client', 'branch');
const ReportRepository = Repository.get('client', 'report');

export default {
	namespaced: true,
	state: {
		branchOffices: [],
		branch: null,
		branchTypes: [],
		branchEmployees: [],
		branchActivities: [],
		branchOfficesForMap: [],
		organizationFeatures: null,
	},
	mutations: {
		LOAD_BRANCH_OFFICES: (state, payload) => {
			state.branchOffices = payload;
		},
		LOAD_BRANCH_OFFICES_BY_REGIONAL_ID: (state, payload) => {
			state.branchOffices = payload;
		},
		LOAD_BRANCH_OFFICES_FOR_DROPDOWN: (state, payload) => {
			state.branchOfficesForDropdown = payload;
		},
		LOAD_BRANCH_BY_ID: (state, payload) => {
			state.branch = payload;
		},
		LOAD_BRANCH_TYPES: (state, payload) => {
			state.branchTypes = payload;
		},
		LOAD_BRANCH_EMPLOYEES: (state, payload) => {
			state.branchEmployees = payload;
		},
		LOAD_BRANCH_ACTIVITIES: (state, payload) => {
			state.branchActivities = payload;
		},
		LOAD_BRANCH_OFFICES_FOR_MAP: (state, payload) => {
			state.organizationFeatures = payload.organizationFeatures;
			state.branchOfficesForMap = payload.branchesForMap;
		},
		UPDATE_BRANCH_STATUS: (state, { id, newStatus }) => {
			for (const i in state.branchOfficesForMap) {
				if (state.branchOfficesForMap[i].id === id) {
					state.branchOfficesForMap[i].branchAction = newStatus;
					break;
				}
			}
		},
	},
	actions: {
		async getBranchOfficesFromRepo ({ commit }, object) {
			commit('LOAD_BRANCH_OFFICES', await BranchRepository.get(object));
		},

		async getBranchByRegionalId ({ commit }, id) {
			commit('LOAD_BRANCH_OFFICES_BY_REGIONAL_ID', await BranchRepository.getBranchesInRegion(id));
		},

		async getBranchById ({ commit }, id) {
			commit('LOAD_BRANCH_BY_ID', await BranchRepository.getBranchById(id));
		},

		async getBranchTypes ({ commit }) {
			commit('LOAD_BRANCH_TYPES', await BranchRepository.getBranchTypes());
		},

		async storeBranchOffice ({ commit }, object) {
			const response = await BranchRepository.store(object);
			return response;
		},

		async updateBranchOffice ({ commit }, object) {
			const response = await BranchRepository.update(object);
			return response;
		},

		async updateBranchDetails ({ commit }, object) {
			const response = await BranchRepository.updateBranchDetails(object);
			return response;
		},

		async updateBranchSchedule ({ commit }, object) {
			const response = await BranchRepository.updateBranchSchedule(object);
			return response;
		},

		async updateBranchHolidays ({ commit }, object) {
			const response = await BranchRepository.updateBranchHolidays(object);
			return response;
		},

		async getBranchEmployees ({ commit }, id) {
			commit('LOAD_BRANCH_EMPLOYEES', await BranchRepository.getBranchEmployees(id));
		},

		async getBranchActivities ({ commit }, id) {
			commit('LOAD_BRANCH_ACTIVITIES', await ReportRepository.getBranchActivities(id));
		},
		async getBranchOfficesForMap ({ commit }) {
			commit('LOAD_BRANCH_OFFICES_FOR_MAP', await BranchRepository.getBranchOfficesForMap());
		},
		async updateBranchStatusForMap ({ state, commit }, data) {
			const branch = state.branchOfficesForMap.find(b => b.id === data.BranchOfficeId);
			if (branch) {
				commit('UPDATE_BRANCH_STATUS', { id: branch.id, newStatus: data.BranchAction });
			} else {
				// If for some reason we do not have this branch on the map,
				// (that most likely means it was newly created), we will just
				// reload all of the map data here.
				commit('LOAD_BRANCH_OFFICES_FOR_MAP', await BranchRepository.getBranchOfficesForMap());
			}
		},
		async setBranchTestMode ({ commit }, object) {
			const response = await BranchRepository.setBranchTestMode(object);
			return response;
		},
	},
	getters: {
		getBranches (state) {
			return state.branchOfficesForMap;
		},
	},
};
