import store from './../store/index';

import ReconnectingWebSocket from 'reconnecting-websocket';

let ws;

function isOpen () {
	return ws && ws.readyState === WebSocket.OPEN;
}

function send (messageStr) {
	if (ws && isOpen()) {
		ws.send(messageStr);
	} else {
		// console.log('Tried to send message on closed WebSocket: ' + messageStr);
	}
}

export default function connectWebSocket () {
	// const url = process.env.VUE_APP_NOTIFICATION;
	const url = `wss://${store.state.authentication.apiServer}/_socket`;

	ws = new ReconnectingWebSocket(url);

	const chatMessageTypes = [
		'mobileChatRequest',
		'onChatRequest',
		'initmobile',
		'terminateChatRequest',
		'terminateChatRequestFromMobile',
		'mobileChatRoomCreated',
		'chatMsgFromMobile',
		'mobileChatClosed',
		'webChatClosed',
		'chatMsgFromOtherConsole',
		'silentTerminateChatRequest',
	];

	const timerMessageTypes = [
		'START-CHECKINOUT-PINGS',
		'CHECKINOUT-PINGS',
		'STOP-CHECKINOUT-PINGS',
	];

	ws.onopen = () => {
		const message = {
			token: store.state.authentication.sessionToken,
			UserId: store.state.authentication.userObject.UserInfos.UserId,
			type: 'init',
		};
		send(JSON.stringify(message));
	};

	ws.onmessage = (message) => {
		var data = JSON.parse(message.data);
		var msgType = data.Type || data.type;
		const branches = store.getters['branches/getBranches'];
		if (msgType && msgType !== 'checkInOutTimerAlert') {
			if (chatMessageTypes.includes(msgType)) {
				chatRinger(msgType);
				store.commit('chat/HANDLE_CHAT_MESSAGE_TYPES', data);
			}

			if (timerMessageTypes.includes(msgType)) {
				store.commit('timer/HANDLE_TIMER_MESSAGE_TYPES', data);
			}

			// var handlerName = makeHandlerNameFromMessageType(msgType);
			// if (typeof handlers[handlerName] === 'function') {
			// 	handlers[handlerName](data);
			// } else if (typeof handlers.noDND[handlerName] === 'function') {
			// 	// Handlers in this group are only run if DND is off
			// 	if (!User.UserSettings.NotificationDoNotDisturb) {
			// 		handlers.noDND[handlerName](data);
			// 	}
			// } else {
			// 	console.error('Unknown WS message type received', data);
			// }
			if (msgType === 'onlineConsoles') {
				store.commit('socket/UPDATE_ONLINE_CONSOLES', data.WC);
			}

			if (msgType === 'EMERGENCY-NOTIFICATION') {
				store.commit('notifications/UPDATE_EMERGENCY_NOTIFICATION', data);
				if (data.BranchAction) {
					switch (data.BranchAction) {
					case 'Chat':
					case 'Video':
						break;
					default:
						store.dispatch('branches/updateBranchStatusForMap', data);
					}
				}
			}
			// if (data.BranchAction && (data.BranchAction.includes('Trouble') || data.BranchAction.includes(''))) {
			// 	store.dispatch('branches/updateBranchStatusForMap', data);
			// }
		} else {
			const branchIndex = branches.findIndex(b => b.id === data.BranchOfficeId);
			if (data.BranchAction && branches[branchIndex]) {
				if ((data.BranchAction.indexOf('Trouble') > -1 && data.Msg.indexOf('panic') > -1) || data.BranchAction === 'Panic - Video' || data.BranchAction === 'Panic - Chat') {
					store.commit('notifications/UPDATE_PANIC_NOTIFICATIONS', data);
				} else if (data.BranchAction.indexOf('Trouble') > -1) {
					store.commit('notifications/UPDATE_ALARM_NOTIFICATIONS', data);
				} else if (data.BranchAction.indexOf('Not Opened') > -1 || data.BranchAction.indexOf('Not Closed') > -1) {
					// showAnomalyNotification(data);
					// console.log('SYSTEM_EVENT');
				} else {
					if (data.Msg.indexOf('Serviced') > -1 && data.BranchAction !== 'Serviced') {
						data.BranchAction = 'Serviced';
					}
					store.commit('timer/HANDLE_TIMER_MESSAGE_TYPES', data);
					store.commit('notifications/UPDATE_ALL_NOTIFICATIONS', data);
				}
			}
			// If the WebSocket data has a new action, and that action
			// is not a system "Not Opened" or "Not Closed" event,
			// then we need to update the map
			if (data.BranchAction && !data.BranchAction.startsWith('Not ')) {
				store.dispatch('branches/updateBranchStatusForMap', data);
			}
		}
	};

	// ws.onerror = (evt) => {
	// 	console.log('error', evt);
	// };

	// ws.onclose = (message) => {
	// 	console.log('ONCLOSE', message);
	// };

	function isOpen () {
		return ws && ws.readyState === WebSocket.OPEN;
	}

	function chatRinger (msgType) {
		switch (msgType) {
		case 'mobileChatRequest':
			store.commit('addRinging', null);
			break;
		case 'terminateChatRequest':
		case 'terminateChatRequestFromMobile':
		case 'mobileChatRoomCreated':
		case 'mobileChatClosed':
		case 'webChatClosed':
		case 'silentTerminateChatRequest':
			store.commit('removeRinging', null);
			break;
		default:
			break;
		}
	}

	store.subscribe((mutation, state) => {
		switch (mutation.type) {
		case 'authentication/LOGOUT':
			if (isOpen()) {
				ws.close();
			}
			break;
		}
	});
}
