import DailyReport from '@/lib/models/Client/DailyReport';
import ActivityReport from '@/lib/models/Client/ActivityReport';
import ActivityReportDetails from '@/lib/models/Client/ActivityReportDetails';
import CheckInOutReport from '@/lib/models/Client/CheckInOutReport';
import SurveyReport from '@/lib/models/Client/SurveyReport';
import SummaryReport from '@/lib/models/Client/SummaryReport';
import TimerReport from '@/lib/models/Client/TimerReport';
import Timer from '@/lib/models/Client/Timer';
import SurveyReportDetails from '@/lib/models/Client/SurveyReportDetails';
import { ReportError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async getDailyReports (object) {
		object.case = 'GET-ALL-BRANCH-REPORTING';

		const response = await $http.post('/api/client/dailyreports', object, {
			responseType: object.format === 'csv' ? 'blob' : undefined,
		});

		if (object.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			const reports = data.map(r => {
				const report = new DailyReport(r);
				return report;
			});
			return reports;
		} else {
			throw new ReportError('Error retrieving Daily Reports');
		}
	},

	async getActivityReports (object) {
		object.case = 'GET-ALL-REPORTS';

		const response = await $http.post('/api/client/getnotifications', object, {
			responseType: object.format === 'csv' ? 'blob' : undefined,
		});

		if (object.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.notificationobject || [];
			const data2 = response.data.body.temp_ens_notificationobject || [];
			if (data2.length > 0) {
				Array.prototype.push.apply(data, data2);
			}
			const reports = data.map(r => {
				const report = new ActivityReport(r);
				return report;
			});
			return reports;
		} else {
			throw new ReportError('Error retrieving Activity Reports');
		}
	},

	async getActivityReportDetails (id) {
		const body = {
			case: 'GET-REPORT-DETAILS-BY-NOTIFICATION-ID',
			taskid: id,
		};

		const response = await $http.post('/api/client/getnotifications', body);

		if (response.data?.success) {
			const data = response.data.body.notificationobject;
			const reports = new ActivityReportDetails(data);
			return reports;
		} else {
			throw new ReportError('Error retrieving Activity Report Details');
		}
	},

	async getCheckInOutReports (body) {
		const response = await $http.post('/api/client/getcheckinout', body, {
			responseType: body.format === 'csv' ? 'blob' : undefined,
		});

		if (body.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const reports = data.map(r => {
					const report = new CheckInOutReport(r);
					return report;
				});
				return reports;
			} else {
				return [];
			}
		} else {
			throw new ReportError('Error retrieving Check In/Out Reports');
		}
	},

	async getSurveyReports (object) {
		object.case = 'GET-ALL-SURVEY-REPORTING';

		const response = await $http.post('/api/client/managesurveys', object, {
			responseType: object.format === 'csv' ? 'blob' : undefined,
		});

		if (object.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject || [];
			const reports = data.map(r => {
				const report = new SurveyReport(r);
				return report;
			});
			return reports;
		} else {
			throw new ReportError('Error retrieving Survey Reports');
		}
	},

	async getBranchActivities (id) {
		const body = {
			case: 'GET-ACTIVITIES-BY-BRANCH-ID',
			branchOfficeId: `${id}`,
		};

		const response = await $http.post('/api/client/managebranchoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const activities = data.map(a => {
				const activity = new ActivityReport(a);
				return activity;
			});
			return activities;
		} else {
			throw new ReportError('Error retrieving Branch Activities');
		}
	},

	async getSurveyReportDetails (object) {
		object.case = 'GET-SURVEY-REPORTING-BY-ID';

		const response = await $http.post('/api/client/managesurveys', object);

		if (response.data?.success) {
			const data = response.data.body.temp_surveyobject;
			const report = new SurveyReportDetails(data);
			return report;
		} else {
			throw new ReportError('Error retrieving Survey Report Details');
		}
	},

	async getSummaryReports (object) {
		const response = await $http.post('/api/client/managesummaryreports', object);

		if (response.data?.status !== 200) {
			throw new ReportError('Error retrieving Summary Reports');
		} else {
			if (response.data?.success) {
				const data = response.data.body.resultobject.APPObject;
				const report = new SummaryReport(data);
				return report;
			} else return response;
		}
	},

	async getTimerReports (object) {
		object.case = 'GET-ALL-NON-RETAIL-PINGS';

		const response = await $http.post('/api/client/gettrackings', object, {
			responseType: object.format === 'csv' ? 'blob' : undefined,
		});

		if (object.format === 'csv') {
			return response.data;
		}

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const reports = data.map(r => {
					const report = new TimerReport(r);
					return report;
				});
				return reports;
			} else {
				return [];
			}
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},
	async getTimerTrackings () {
		const body = {
			case: 'GET-NON-RETAIL-PINGS',
		};

		const response = await $http.post('/api/client/gettrackings', body);

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const reports = data.map(r => {
					const report = new Timer(r);
					return report;
				});
				return reports;
			} else {
				return [];
			}
		} else {
			throw new ReportError('Error retrieving Timer Reports');
		}
	},

	async getLogAccessReport (fromDate, toDate, search, format = 'json') {
		const body = {
			fromDate,
			toDate,
			search,
			format,
		};

		const response = await $http.post('/api/client/log-access/reports', body, {
			responseType: format === 'csv' ? 'blob' : undefined,
		});

		if (format === 'csv') {
			return response.data;
		}

		if (response.data.success) {
			return response.data.body.resultobject || [];
		} else {
			throw new ReportError('Error retrieving VaultTrak™ report');
		}
	},

	async getLogAccessDetails (id) {
		const body = {
			id,
			// The server requires these to be present, but doesn't use them
			fromDate: '2000-01-01',
			toDate: '2999-12-31',
		};

		const response = await $http.post('/api/client/log-access/reports', body);

		if (response.data.success) {
			return response.data.body.resultobject[0] || {};
		} else {
			throw new ReportError('Error retrieving VaultTrak™ report');
		}
	},
};
